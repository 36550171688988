import React, { useState, Suspense, useEffect } from 'react';
import { TError } from 'librechat-data-provider';

const bounceKeyframes = `
  @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-20px);
    }
  }
`;

const LoadingFallback = () => (
  <>
    <style>{bounceKeyframes}</style>
    <div
      style={{
        display: 'flex',
        height: '100%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#343541',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '1.5rem',
          fontSize: '1.25rem',
          color: '#e2e8f0',
        }}
      >
        <img
          src="/assets/ChatBotPro-logo.png"
          alt="ChatBotPro logo"
          style={{
            height: 'auto',
            width: '150px',
            animation: 'bounce 1s infinite',
          }}
          width="841"
          height="512"
        />
        <p>Activating AI chat...</p>
      </div>
    </div>
  </>
);

type ProviderValue = {
  error: TError;
  setError: React.Dispatch<React.SetStateAction<boolean>>;
};
const ApiErrorBoundaryContext = React.createContext<ProviderValue | undefined>(undefined);

export const ApiErrorBoundaryProvider = ({
  value,
  children,
}: {
  value: ProviderValue;
  children: React.ReactNode;
}) => {
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      <Suspense fallback={<LoadingFallback />}>
        {isLoading ? (
          <LoadingFallback />
        ) : (
          <ApiErrorBoundaryContext.Provider value={value || { error, setError }}>
            {children}
          </ApiErrorBoundaryContext.Provider>
        )}
      </Suspense>
    </>
  );
};

export const useApiErrorBoundary = () => {
  const context = React.useContext(ApiErrorBoundaryContext);

  if (context === undefined) {
    throw new Error('useApiErrorBoundary must be used inside ApiErrorBoundaryProvider');
  }

  return context;
};
