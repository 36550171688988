import { useState } from 'react';
import type { TConversation, TMessage } from 'librechat-data-provider';
import { Clipboard, CheckMark, EditIcon, RegenerateIcon, ContinueIcon } from '~/components/svg';
import { useGenerations } from '~/hooks';
import { cn } from '~/utils';
import { LuDownload } from 'react-icons/lu';
import { IoCopyOutline } from 'react-icons/io5';
import LanguageOption from './LanguageOption';
type THoverButtons = {
  isEditing: boolean;
  enterEdit: (cancel?: boolean) => void;
  copyToClipboard: (setIsCopied: React.Dispatch<React.SetStateAction<boolean>>) => void;
  conversation: TConversation | null;
  isSubmitting: boolean;
  message: TMessage;
  regenerate: () => void;
  handleContinue: (e: React.MouseEvent<HTMLButtonElement>) => void;
  hasImage: boolean;
  copyImage: (setIsCopiedImage: React.Dispatch<React.SetStateAction<boolean>>) => void;
  downloadImage: () => void;
  text: string;
  submitLanguage: (language: string) => void;
};

export default function HoverButtons({
  text,
  isEditing,
  enterEdit,
  copyToClipboard,
  conversation,
  isSubmitting,
  message,
  regenerate,
  handleContinue,
  hasImage,
  copyImage,
  downloadImage,
  submitLanguage,
}: THoverButtons) {
  const isLimited = text.includes('=== LIMITED ACCESS ===');
  const { endpoint } = conversation ?? {};
  const [isCopied, setIsCopied] = useState(false);
  const [isCopiedImage, setIsCopiedImage] = useState(false);
  const { hideEditButton, regenerateEnabled, continueSupported } = useGenerations({
    isEditing,
    isSubmitting,
    message,
    endpoint: endpoint ?? '',
  });
  if (!conversation) {
    return null;
  }

  const { isCreatedByUser } = message;

  const onEdit = () => {
    if (isEditing) {
      return enterEdit(true);
    }
    enterEdit();
  };

  return (
    <div className="hover-buttons visible mt-2 flex items-center h-[25px] gap-3 text-gray-400 md:gap-4 lg:top-0 lg:mt-0 lg:gap-1 lg:pl-2">
      {hasImage && (
        <>
          {/* <button
            className="hover-button rounded-md p-1 text-gray-500   dark:text-gray-400  "
            onClick={() => copyImage(setIsCopiedImage)}
            type="button"
            title="Copy image"
          >
            {isCopiedImage ? <CheckMark /> : <IoCopyOutline />}
          </button> */}

          <button
            className="hover-button rounded-md p-1 text-gray-500 dark:text-gray-400 tooltip-container"
            onClick={downloadImage}
            type="button"
            tooltip="Download Image"
          >
            <LuDownload />
          </button>
        </>
      )}

      {!isLimited && isCreatedByUser ? (
      <div className={`bg-[#F6F6F6] dark:bg-[#22252A] p-[4px] rounded-md ${hideEditButton ? 'opacity-0' : ''}`}>
        <button
          className={cn(
            'hover-button rounded-md border-none p-1 dark:text-gray-400 text-[#7B7B7B] disabled:dark:hover:text-gray-400 tooltip-container',
            isEditing ? 'active bg-gray-100 text-gray-700 dark:bg-gray-700 dark:text-gray-200' : '',
          )}
          onClick={onEdit}
          type="button"
          tooltip="Edit"
          disabled={hideEditButton}
        >
          <EditIcon />
        </button>
      </div>
      ) : null}
      {!isSubmitting && !isLimited && endpoint !== 'dallE' && (
        <div className='bg-[#F6F6F6] dark:bg-[#22252A] p-[4px] rounded-md'>
          <button
            className={cn(
              'hover-button rounded-md border-none p-1 dark:text-gray-400 text-[#7B7B7B] disabled:dark:hover:text-gray-400  tooltip-container',
              isCreatedByUser ? '' : 'active',
            )}
            onClick={() => copyToClipboard(setIsCopied)}
            type="button"
            tooltip={isCopied ? 'Copied to clipboard' : 'Copy to clipboard'}
          >
            {isCopied ? <CheckMark /> : <Clipboard />}
          </button>
        </div>
      )}
      {regenerateEnabled && !isLimited ? (
        <div className='bg-[#F6F6F6] dark:bg-[#22252A] p-[4px] rounded-md'>
          <button
            className="hover-button active rounded-md border-none p-1 text-[#7B7B7B] dark:text-gray-400   disabled:dark:hover:text-gray-400  tooltip-container"
            onClick={regenerate}
            type="button"
            tooltip="Regenerate"
          >
            <RegenerateIcon className=" disabled:dark:hover:text-gray-400" />
          </button>
        </div>
      ) : null}
      {continueSupported && !isLimited ? (
        <div className='bg-[#F6F6F6] dark:bg-[#22252A] p-[4px] rounded-md'>
          <button
            className="hover-button active rounded-md border p-1 dark:text-gray-400 disabled:dark:hover:text-gray-400 tooltip-container"
            onClick={handleContinue}
            type="button"
            tooltip="continue"
          >
            <ContinueIcon className="h-4 w-4 disabled:dark:hover:text-gray-400" />
          </button>
        </div>
      ) : null}
      {endpoint !== 'dallE' && (
        !isCreatedByUser && !isLimited && !isSubmitting && conversation.endpoint !== 'google' && <LanguageOption submitLanguage={submitLanguage} />
      )}
    </div>
  );
}
