import { Fragment, useEffect, useState, useRef } from 'react';
import type { TResPlugin } from 'librechat-data-provider';
import type { TMessageContent, TText, TDisplayProps } from '~/common';
import { cn, getError } from '~/utils';
import EditMessage from './EditMessage';
import Container from './Container';
import Markdown from './Markdown';
import Plugin from './Plugin';
import { usePopup, usePricingPopup } from '../../helper/store';
import { setCookieValue, showPricing } from '~/components/helper/global';
import { useSetOptions } from '~/hooks';
import { useCustomData } from '~/components/helper/store';

const getCookieValue = (key: string) => {
  let keyValue = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)');
  return keyValue ? decodeURIComponent(keyValue[2]) : null;
};

const setCookie = (key, value) => {
  document.cookie = key + '=' + encodeURIComponent(value) + '; domain=.chatbotpro.org; path=/';
};

const setFlagFromURL = (flagName) => {
  const flagValue = new URLSearchParams(window.location.search).get(flagName);
  if (flagValue !== null && flagValue !== '') {
    setCookie(flagName, flagValue);
  }
};

setFlagFromURL('regRedirectWP');
setFlagFromURL('ppg');
setFlagFromURL('flow');
setFlagFromURL('pmt');

// Error Message Component
const ErrorMessage = ({ text }: TText) => (
  <Container>
    <div className="rounded-md border border-red-500 bg-red-500/10 px-3 py-2 text-sm text-gray-600 dark:text-gray-100">
      {getError(text)}
    </div>
  </Container>
);

// Display Message Component
const DisplayMessage = ({ text, isCreatedByUser, message, showCursor }: TDisplayProps) => {
  if (!text) return;
  const marker = '=== LIMITED ACCESS ===';
  const error_marker = '=== ERROR RESPONSE ===';
  const gpt_4o_max_token_reached_marker = '=== GPT-4o MAX TOKEN REACHED ===';
  const access_restricted_marker = '=== ACCESS RESTRICTED ===';

  let isLimited = false;
  let isError = false;
  let gpt_4o_max_token_reached = false;
  let access_restricted = false;

  let _text = text;
  if (text.includes(marker)) {
    isLimited = true;
    _text = text.replace(marker, '');
  } else if (text.includes(error_marker)) {
    isError = true;
    _text = text.replace(error_marker, '');
  } else if (text.includes(gpt_4o_max_token_reached_marker)) {
    gpt_4o_max_token_reached = true;
    _text = text.replace(gpt_4o_max_token_reached_marker, '');
  } else if (text.includes(access_restricted_marker)) {
    access_restricted = true;
    _text = text.replace(access_restricted_marker, '');
  }
  const sState = usePricingPopup((state) => state);
  const wpCtaLink = getCookieValue('regRedirectWP');
  const wpFlow = getCookieValue('flow');
  const wpPpg = getCookieValue('ppg');
  const userEmail = getCookieValue('user_email');
  const wpBaseURL = import.meta.env.VITE_WP_BASE_URL || 'https://chatbotpro.org';
  const wpRegIsFlagged = (wpCtaLink && wpCtaLink) === '1';
  const registerLogicUrl =
    wpRegIsFlagged && wpFlow === '04' ? '/select-account-type-d' : '/register';
  const appendPPG = wpPpg && wpPpg !== '' ? `?ppg=${wpPpg}` : '';
  const pStatePop = usePopup((state) => state);

  const { setOption } = useSetOptions();
  const hasFile = useCustomData((state) => state.hasFile);
  const [hovered, setHovered] = useState(false);
  const [isBgHovered, setIsBgHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsBgHovered(true);
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
    setIsBgHovered(false);
  };

  const handleImgMouseEnter = () => {
    setIsBgHovered(true);
  };

  const handleImgMouseLeave = () => {
    setIsBgHovered(false);
  };
  
  const handleDownloadClick = async () => {

    const convertImageToBase64 = async (imageUrl) => {
      try {
        // Fetch the image from the URL
        const response = await fetch(imageUrl, {
          method: 'GET',
          // Include credentials if needed
          credentials: 'same-origin',
          headers: {
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            'Pragma': 'no-cache',
            'Expires': '0'
          }
        });
  
        // Check if the response is OK
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        // Convert the response to a Blob
        const blob = await response.blob();
  
        // Create a FileReader to read the Blob
        const reader = new FileReader();
  
        return new Promise((resolve, reject) => {
          reader.onloadend = () => {
            // Resolve the base64 string when the FileReader has finished reading
            resolve(reader.result);
          };
  
          reader.onerror = reject;
  
          // Read the Blob as a Data URL
          reader.readAsDataURL(blob);
        });
      } catch (error) {
        console.error('Error converting image to base64:', error);
      }
    };
  
    // Usage example
    const imageUrl = message.s3_path;
    
    convertImageToBase64(imageUrl).then(base64String => {
      // Use the base64 string as needed
      const link = document.createElement('a');
      link.href = base64String;
      link.setAttribute('download', message.image_name || 'image.png');

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  const [isLoaded, setIsLoaded] = useState(false);
  const imgRef = useRef(null);

  useEffect(() => {
    const handleImageLoad = () => {
      setIsLoaded(true);
    };

    const imgElement = imgRef.current;
    
    if (imgElement) {
      if (imgElement.complete && imgElement.naturalHeight !== 0) {
        handleImageLoad();
      } else {
        imgElement.addEventListener('load', handleImageLoad);
      }
    }

    return () => {
      if (imgElement) {
        imgElement.removeEventListener('load', handleImageLoad);
      }
    };
  }, []);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <Container>
        <div
        style={{ direction: 'ltr' }}
          className={cn(
            'display-message markdown prose dark:prose-invert light w-full break-words ',
            isCreatedByUser ? 'whitespace-pre-wrap' : '',
            isLimited || isError || gpt_4o_max_token_reached || access_restricted ? 'text-[##6d4040]' : '',
          )}
          data-id={message.messageId}
        >
          {!isCreatedByUser ? (
          message.model === 'dall-e-3' && !isLimited ? (
              <>
                Here is your image:
                <br/>
                <div className='relative'>
                  <img 
                    ref={imgRef}
                    className={`rounded-md transition ${isLoaded ? 'blur-0' : 'blur-lg'}`}
                    src={text} 
                    alt="image preview" 
                    loading='lazy'
                    onError={(e) => { 
                      e.target.src = message.s3_path; 
                    }}
                    onMouseEnter={handleImgMouseEnter}
                    onMouseLeave={handleImgMouseLeave}
                  />
                  <button 
                    className='absolute top-0 right-0 m-2 p-3 rounded-full text-xs' 
                    style={{
                      display: !isBgHovered && !isMobile ? 'none' : 'block',
                      backgroundColor: !hovered ? 'rgba(0, 0, 0, 0.8)' : 'rgba(0, 0, 0, 0.4)',
                      transition: 'background-color 0.3s ease-in-out',
                      color: '#fff',
                      border: 'none',
                      cursor: 'pointer',
                    }}
                    hidden={!isLoaded ? true : false}
                    onClick={handleDownloadClick}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <svg class="h-6 w-6 text-white"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" />  <polyline points="7 11 12 16 17 11" />  <line x1="12" y1="4" x2="12" y2="16" /></svg>
                  </button>
                </div>
                I hope you like it! Let me know if there's anything else I can assist you with.
              </>
            ) : (
              <Markdown content={_text} message={message} showCursor={showCursor} />
          )
        ) : (
          <>{_text}</>
        )}
        </div>
      </Container>
      <div className="flex flex-wrap pt-2">
        {(isLimited || gpt_4o_max_token_reached) && (
          <div
          style={{
            background: 'linear-gradient(to bottom, #FFC123 0%, #E3A400 100%)',
            color: 'white',
          }}
          className="isLimited shadow cursor-pointer rounded bg-black mr-2 mb-2 px-4 py-2 text-white text-center absolute bottom-[-20px] left-0"
            onClick={() => {
              if (!userEmail && wpRegIsFlagged) {
                window.location.href = wpBaseURL + registerLogicUrl + appendPPG;
              } else {
                pStatePop.setMixPanelTrack('');
                pStatePop.setMixPanelClickLocation('upgrade-to-pro');
                setCookieValue('mixPanelTrack', '');
                setCookieValue('mixPanelClickLocation', 'upgrade-to-pro');
                showPricing(() => {
                  sState.setShowPricingPopup(true);
                });
              }
            }}
            role="button"
            tabIndex={0}
            aria-label="Upgrade to PRO"
          >
            Upgrade to PRO
          </div>
        )}

        {gpt_4o_max_token_reached && !hasFile && (
          <div
            className="isLimited cursor-pointer rounded bg-gray-100 mb-2 px-4 py-2 text-black"
            onClick={() => {
              const setModel = setOption('model');
              if (typeof setModel === 'function') {
                setModel('gpt-3.5-turbo');
              }
            }}
            role="button"
            tabIndex={0}
            aria-label="Switch to GPT-3.5"
          >
            Switch to GPT-3.5
          </div>
        )}
      </div>
    </>
  );
};

// Unfinished Message Component
const UnfinishedMessage = () => (
  <ErrorMessage text="This is an unfinished message. The AI may still be generating a response, it was aborted, or a censor was triggered. Refresh or visit later to see more updates." />
);

// Content Component
const MessageContent = ({
  text,
  edit,
  error,
  unfinished,
  isSubmitting,
  isLast,
  ...props
}: TMessageContent) => {
  if (error) {
    return <ErrorMessage text={text} />;
  } else if (edit) {
    return <EditMessage text={text} isSubmitting={isSubmitting} {...props} />;
  } else {
    const marker = ':::plugin:::\n';
    const splitText = text.split(marker);
    const { message } = props;
    const { plugins, messageId } = message;
    const displayedIndices = new Set<number>();
    // Function to get the next non-empty text index
    const getNextNonEmptyTextIndex = (currentIndex: number) => {
      for (let i = currentIndex + 1; i < splitText.length; i++) {
        // Allow the last index to be last in case it has text
        // this may need to change if I add back streaming
        if (i === splitText.length - 1) {
          return currentIndex;
        }

        if (splitText[i].trim() !== '' && !displayedIndices.has(i)) {
          return i;
        }
      }
      return currentIndex; // If no non-empty text is found, return the current index
    };

    return splitText.map((text, idx) => {
      let currentText = text.trim();
      let plugin: TResPlugin | null = null;

      if (plugins) {
        plugin = plugins[idx];
      }

      // If the current text is empty, get the next non-empty text index
      const displayTextIndex = currentText === '' ? getNextNonEmptyTextIndex(idx) : idx;
      currentText = splitText[displayTextIndex];
      const isLastIndex = displayTextIndex === splitText.length - 1;
      const isEmpty = currentText.trim() === '';
      const showText =
        (currentText && !isEmpty && !displayedIndices.has(displayTextIndex)) ||
        (isEmpty && isLastIndex);
      displayedIndices.add(displayTextIndex);

      return (
        <Fragment key={idx}>
          {plugin && <Plugin key={`plugin-${messageId}-${idx}`} plugin={plugin} />}
          {showText ? (
            <DisplayMessage
              key={`display-${messageId}-${idx}`}
              showCursor={isLastIndex && isLast}
              text={currentText}
              {...props}
            />
          ) : null}
          {!isSubmitting && unfinished && (
            <UnfinishedMessage key={`unfinished-${messageId}-${idx}`} />
          )}
        </Fragment>
      );
    });
  }
};

export default MessageContent;
