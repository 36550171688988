import React, { useState } from 'react';
import { cn } from '~/utils';
import { FaArrowRightLong, FaChevronLeft, FaChevronRight } from 'react-icons/fa6';
import { FaCheck } from 'react-icons/fa';
import { useMemo } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

type PricingPopupCardType = {
  currentPlan: boolean;
  label: string;
  price: number;
  plan_type: string;
  onClick?: () => void;
  display_txt2: string;
};

const PricingPopupCard: React.FC<PricingPopupCardType> = ({
  currentPlan,
  label,
  price,
  plan_type,
  onClick,
  display_txt2 = '',
}) => {
  const components = useMemo(() => buildComponents(display_txt2), [display_txt2]);

  const [[page, direction], setPage] = useState([0, 0]);
  const paginate = (newDirection: number) => {
    if (page + newDirection <= 0) {
      setPage([0, newDirection]);
    } else if (page + newDirection >= components.length - 1) {
      setPage([components.length - 1, newDirection]);
    } else {
      setPage([page + newDirection, newDirection]);
    }
  };

  return (
    <div
      className={cn(
        'mt-auto flex w-[80vw] max-w-[256px] flex-col items-center rounded-lg border border-[#C1C1C1] bg-white px-3 pt-3',
        {
          'border-[#177755]': plan_type !== '',
        },
      )}
    >
      <div className="mb-3 mt-8 flex flex-col items-center">
        <h2 className="mb-1 text-xl font-bold">{label}</h2>
        <h1 className="text-4xl font-bold">
          ${price === 0 ? 0 : (price / 30).toFixed(2)}
          <span className="text-sm font-semibold">/day</span>
        </h1>
        <h3 className="mb-3 text-sm">or ${price} monthly</h3>
        <button
          onClick={onClick}
          disabled={currentPlan}
          className={cn('rounded-[10px] bg-[#177755] px-[26px] py-1 font-semibold text-white', {
            'bg-[#D9D9D9] text-black': currentPlan,
          })}
        >
          {currentPlan ? 'Your Current Plan' : 'Subscribe'}{' '}
          {!currentPlan && (
            <span className="inline-block align-text-bottom">
              <FaArrowRightLong />
            </span>
          )}
        </button>
      </div>
      <hr
        className={cn('mb-4 h-[1px] w-full border-none bg-[#C1C1C1]', {
          'bg-[#177755]/50': plan_type !== '',
        })}
      />
      <div className="relative flex h-[200px] w-full items-center justify-center overflow-hidden">
        <AnimatePresence custom={direction}>
          <motion.div
            key={`description-${page}`}
            variants={variants}
            custom={direction}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{ duration: 0.5 }}
            className="absolute w-[232px] grow self-start px-6"
          >
            {components[page]}
          </motion.div>
        </AnimatePresence>
        {plan_type !== '' && (
          <FaChevronRight
            className={cn('z-2 absolute right-0', {
              'opacity-50': components.length - 1 === page,
            })}
            onClick={() => paginate(1)}
          />
        )}
        {plan_type !== '' && (
          <FaChevronLeft
            className={cn('z-2 absolute left-0', {
              'opacity-50': page === 0,
            })}
            onClick={() => paginate(-1)}
          />
        )}
      </div>
      <div className="mb-4 mt-2 flex items-center justify-center">
        {[...Array(components.length).keys()].map((_, idx, a) => {
          const isActive = idx === page;
          if (a.length <= 1) {
            return <div key={`dots-${idx}`} className="h-[7px]" />;
          }
          return (
            <div
              key={`dots-${idx}`}
              className={cn('mr-1 h-[7px] w-[7px] rounded-full bg-[#737373]/20 last:mr-0', {
                'bg-[#737373]/50': isActive,
              })}
            />
          );
        })}
      </div>
    </div>
  );
};

const variants = {
  enter: (direction) => {
    return { x: direction > 0 ? 500 : -500, opacity: 0 };
  },
  center: { x: 0, opacity: 1 },
  exit: (direction) => {
    console.log(direction);
    return { x: direction < 0 ? 500 : -500, opacity: 0 };
  },
};

const buildComponents = (htmlString: string) => {
  const parser = new DOMParser();
  const htmlDoc = parser.parseFromString(htmlString, 'text/html');
  const descriptions = Array.from(htmlDoc.getElementsByTagName('div'))
    .map((el) => {
      const trimmedStr = el.textContent?.trim();
      const index = trimmedStr?.indexOf('\n');
      return trimmedStr?.substring(0, index != -1 ? index : trimmedStr.length);
    })
    .filter((e) => e !== undefined);

  const finalDescriptions = splitArray(Array.from(descriptions), 5);
  const components = (
    htmlString
      ? finalDescriptions
      : [
          [
            '3 messages daily',
            'Standard Responses',
            '500 character limit',
            'Prompt templates',
            'Ads might be displayed',
          ],
        ]
  ).map((descriptions) => (
    <>
      {descriptions.map((description) => {
        if (description === '') {
          return <div key={description} className="h-[22px] w-full" />;
        }
        return (
          <div key={description} className="mt-2 flex items-center first:mt-0 ">
            <div
              className={cn(
                'mr-2 flex h-[22px] w-[22px] items-center justify-center rounded-full border border-[#737373]/50 bg-[#D9D9D9]',
                {
                  'border-[#177755]/50 bg-[#28F5AB33]/20': Boolean(htmlString),
                },
              )}
            >
              <FaCheck size={10} className={cn({ 'text-[#177755]': Boolean(htmlString) })} />
            </div>
            <h3 className="max-w-[150px] text-sm">{description}</h3>
          </div>
        );
      })}
    </>
  ));

  return components;
};

const splitArray = (arr: string[], size: number): string[] =>
  arr.reduceRight((r, i, _, s) => (r.push(s.splice(0, size)), r), []);

export default PricingPopupCard;
