import React, { useState, useEffect, useRef } from 'react';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import useDocumentTitle from '~/hooks/useDocumentTitle';
import store from '~/store';
import { useLocalize } from '~/hooks';
import { usePopup, subscriptionType } from '../helper/store.js';
import { cn, cookie } from '~/utils';
import { useAppModel } from './EndPoint';
// import HeaderProButton from './HeaderProButton.jsx';
import { useSetOptions } from '~/hooks';
import { showPricing } from '~/components/helper/global';
import { motion } from 'framer-motion';
import { useUpdateConversation } from '~/hooks/useUpdateConversation';
import { MdOutlineArrowRight, MdOutlineArrowDropDown } from "react-icons/md";
import ChatEndpoints from './ChatEndpoints';
import ChatModels from './ChatModels';
import ChatModelDescription from './ChatModelDescription';
import ChatExample from './ChatExample';
import { activeEndpointState } from '~/store/endpoints';

export default function Landing() {
  const [isActive, setIsActive] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [animationKey, setAnimationKey] = useState(0);
  const setText = useSetRecoilState(store.text);
  const conversation = useRecoilValue(store.conversation);
  const localize = useLocalize();
  const { title = localize('com_ui_new_chat') } = conversation || {};
  const chatModelsRef = useRef<HTMLDivElement | null>(null);
  const appModel = useAppModel();
  const [showModel, setShowModel] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  useDocumentTitle(title);
  const openai_gpt4_model = import.meta.env.VITE_OPENAI_GPT4_MODEL || 'gpt-4-1106-preview';
  const openai_default_model = import.meta.env.VITE_FORCE_MODEL || 'gpt-4o-mini';

  const dalle_model = import.meta.env.VITE_DALLE_MODEL || 'dall-e-3';
  const [activeModel, setActiveModel] = useState<string>(openai_default_model);
  // const [activeEndpoint, setActiveEndpoint] = useState<string>('');
  const [activeEndpoint, setActiveEndpoint] = useRecoilState(activeEndpointState);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
  const [isMdScreen, setisMdScreen] = useState(window.innerWidth > 768);
  const updateConversation = useUpdateConversation();
  const sState = usePopup((state) => state);
  const proState = subscriptionType((state) => state);

  const { setOption } = useSetOptions();
  const setValue = setOption('model');
  const { newConversation } = store.useConversation();

  const computeIsDisabled = (endpoint) => { //compute summarized isDisabled value
    const models = appModel.filter(model => model.endpoint === endpoint);
    const hasTrue = models.some(model => model.isDisabled === true);
    const hasFalse = models.some(model => model.isDisabled === false);
    return (hasTrue && !hasFalse) ? true : false;
  };

  const computeIsBeta = (endpoint) => { //compute summarized isBeta value
    const models = appModel.filter(model => model.endpoint === endpoint);
    const hasTrue = models.some(model => model.isBeta === true);
    return hasTrue;
  };

  const computeIsNew = (endpoint) => {  //compute summarized isNew value
    const models = appModel.filter(model => model.endpoint === endpoint);
    const hasTrue = models.some(model => model.isNew === true);
    return hasTrue;
  };

  const { isPro, isProMax, setIsProMax, isEnterprise, setIsEnterprise } = subscriptionType(
    (state) => state,
  );

  const clickHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const { innerText } = e.target as HTMLButtonElement;
    const quote = innerText.trim();
    setText(quote);
  };

  const handleEndpointClick = (endpoint) => {
    // console.log("Endpoint Clicked:", endpoint);
    setActiveEndpoint(endpoint);
    setShowModel(false);

    setTimeout(()=>{
      setShowModel(true); // delay model display for animation
    }, 0);
  };

  const handleModelClick = (model) => {

    setActiveModel(model.model);
    updateConversation(model);
    setAnimationKey(prev => prev + 1);
    
    if (isSmallScreen) {
      setIsOpen(false);
    }
  };

  const handleOptionClick = (model) => {
    if (!model) return;
    setActiveModel(model);
    const activeModel = model;
    if (activeModel.includes('gpt-4') || activeModel.includes('palm')) {
      if (activeModel.includes('palm')) {
        if (conversation?.endpoint !== 'google') {
          newConversation({}, { endpoint: 'google' });
        }
        setTimeout(() => {
          setValue('chat-bison');
        });
      } else if (activeModel == openai_gpt4_model || activeModel == 'gpt-4-pro-max' || activeModel == 'gpt-4o' || activeModel == 'gpt-4o-mini') {
        setValue(activeModel);
        if (conversation?.endpoint !== 'openAI') {
          const lastSelectedModel = localStorage.getItem('lastSelectedModel');
          if (lastSelectedModel) {
            const models = JSON.parse(lastSelectedModel);
            models.openAI = activeModel;
            localStorage.setItem('lastSelectedModel', JSON.stringify(models));
          }

          newConversation({}, { endpoint: 'openAI', model: activeModel });
        }
      }

    } else if(activeModel.includes('claude') && activeModel.includes('sonnet')){
      const lastSelectedModel = localStorage.getItem('lastSelectedModel');
      
      if (lastSelectedModel) {
        const models = JSON.parse(lastSelectedModel);
        models.claude = activeModel;
        localStorage.setItem('lastSelectedModel', JSON.stringify(models));
      }
      newConversation({}, { endpoint: 'claude', model: activeModel });
      setTimeout(() => {
        setValue(activeModel);
      });
    } else if(activeModel === dalle_model) {
      const lastSelectedModel = localStorage.getItem('lastSelectedModel');
     
      // get local storage lastSelectedModel
      if (lastSelectedModel) {
        const models = JSON.parse(lastSelectedModel);
        models.openAI = activeModel;
        localStorage.setItem('lastSelectedModel', JSON.stringify(models));
      }
      newConversation({}, { endpoint: 'dallE', model: activeModel });
    } else if(activeModel === "gemini-1.5-flash"){
      newConversation({}, { endpoint: 'gemini', model: activeModel });
      setTimeout(() => {
        setValue(activeModel);
      });
    } else if (activeModel === 'meta-llama/Llama-2-70b-chat-hf') {
      newConversation({}, { endpoint: 'Opensource' });
      setTimeout(() => {
        setValue(activeModel);
      });
    } else {
      const lastSelectedModel = localStorage.getItem('lastSelectedModel');
      setValue(activeModel);
      if (conversation?.endpoint !== 'openAI') {
        if (lastSelectedModel) {
          const models = JSON.parse(lastSelectedModel);
          models.openAI = activeModel;
          localStorage.setItem('lastSelectedModel', JSON.stringify(models));
        }
        newConversation({}, { endpoint: 'openAI' });
      }
    }

    if (isSmallScreen) {
      setIsOpen(false)
    }
  };

  const user = typeof window.AIPRO_USER !== 'undefined' && window.AIPRO_USER
  useEffect(() => {
    const getUserSubscriptionType = setInterval(() => {
      if (user) {
        const userSubscriptionType = user.subscription_type;
        if (userSubscriptionType) {
          if ( userSubscriptionType === "PRO") {
            proState.setPro(true);
          }
          if (userSubscriptionType === "PROMAX") {
            setIsProMax(true);
          }
          if (userSubscriptionType === "ENTERPRISE") {
            setIsEnterprise(true);
          }
        }
        setIsLoaded(true);
      } 
    }, 300);
    const clearGetUserSubscriptionType = setTimeout(() => {
      clearInterval(getUserSubscriptionType)
    }, 5000)
    return () => clearTimeout(clearGetUserSubscriptionType)
  }, [])

  useEffect(() => {
    if (!isActive) {
      handleOptionClick(openai_default_model)
    }

    setIsActive(openai_default_model);

    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    const handleResizeMd = () => {
      setisMdScreen(window.innerWidth > 639);
    };

    window.addEventListener('resize', handleResizeMd);

    const handleMouseDown = (event) => {

      if (chatModelsRef.current && !chatModelsRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleMouseDown);
    document.addEventListener('touchmove', handleMouseDown);

    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('touchmove', handleMouseDown);
    };
  }, []);

  useEffect(() => {
    if (activeEndpoint) {
      appModel.forEach((model) => {
        if (model && model.isDefault && model.endpoint === activeEndpoint) {
          setActiveModel(model.model);
        }
      });
    }

  }, [activeEndpoint]);

  useEffect(() => {
    setIsOpen(false);
  }, [isMdScreen]);

  useEffect(() => {
    const htmlElement = document.documentElement;
    setIsDarkMode(htmlElement.classList.contains('dark'));

    const observer = new MutationObserver(() => {
      setIsDarkMode(htmlElement.classList.contains('dark'));
    });

    observer.observe(htmlElement, { attributes: true, attributeFilter: ['class'] });

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    setActiveModel(conversation?.model ?? openai_default_model);
    setActiveEndpoint(conversation?.endpoint ?? 'openAI');
  }, []);
  
  const handleDropdownClick = (endpoint) => {
    handleEndpointClick(endpoint);
    setIsOpen(false);
  };

  if (isLoaded === false) {
    return null;
  }
  const uniqueEndpoints = [...new Set(appModel.map(model => model.endpoint))];
  const filteredModels = appModel.filter(model => model.endpoint === activeEndpoint);

  return (
    // <div className="landing-container max-sm:flex max-h-380:min-h-[190vh] max-h-480:min-h-[180vh] md:min-h-[85vh] md:flex justify-center items-center mx-auto mt-[7vh] block h-full min-h-0 w-fit max-w-[calc(100%_-_20px)] pb-24 pt-[20px] md:pt-2 sm:mt-[9vh] md:mt-[4vh] md:pb-44 ">
    // <div className="landing-container  md:min-h-[85vh] md:flex justify-center items-center mx-auto mt-[7vh] block h-full min-h-0 w-fit max-w-[calc(100%_-_20px)] md:pb-20 pt-[20px] md:pt-2 sm:mt-[9vh] md:mt-[4vh]">
      <div
      className={`${
        isSmallScreen
          ? "landing-container max-sm:flex max-w-375:min-h-[84vh] max-h-380:min-h-[190vh] max-h-480:min-h-[180vh] md:min-h-[85vh] md:flex justify-center items-center mx-auto mt-[7vh] block h-full min-h-0 w-fit max-w-[calc(100%_-_20px)] pb-10 pt-[20px] md:pt-2 sm:mt-[9vh] md:mt-[4vh] pb-28 md:pb-48"
          : "landing-container  md:min-h-[85vh] md:flex justify-center items-center mx-auto mt-[7vh] block h-full min-h-0 w-fit max-w-[calc(100%_-_20px)] md:pb-40 pt-[20px] md:pt-2 sm:mt-[9vh] md:mt-[4vh]"
      }`}
    >
      <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{
          type: "spring",
          stiffness: 260,
          damping: 20
        }}
        className={cn('models-container scrollbar-hide mx-auto grid h-max max-h-full w-full relative grid-cols-1 overflow-auto lg:w-[700px] xl:w-[850px] align-top')}>
        {/* <div className="text-right md:pb-2 block sticky sm:static md:pt-0 mb-[-40px] md:mb-2 sm:top-20 right-6 z-99 max-w-320:mr-[2px]">
          <HeaderProButton />
        </div> */}

        { isSmallScreen ? (
          <>
            {/* Mobile Endpoints */}
            <div
              className="mobile activemobile rounded-tl-lg overflow-hidden font-medium border-l-2 z-10 mb-[-2px] border-gray-100 dark:border-[#212429]"
              ref={chatModelsRef} onClick={() => setIsOpen(!isOpen)}
            >
              <div className="activetitl ios-2-cells w-[210px] items-center rounded-t-lg transform skew-x-12 origin-bottom-right pl-6 pr-5 pt-[14px] pb-[6px] bg-white dark:bg-gray-900 text-blue-400 dark:text-blue-400 cursor-pointer border-t-2 border-r-2 border-gray-100 dark:border-[#212429]">
                <div className="text-nowrap text-left capitalize transform -skew-x-12">
                  <span className="text-green-500 mr-2">•</span>
                  {
                    activeEndpoint === 'openAI' 
                    ? 'GPTs' 
                    : activeEndpoint === 'Opensource' 
                    ? 'More LLMs' 
                    : activeEndpoint === 'dallE' 
                    ? 'DALL·E' 
                    : activeEndpoint
                  }

                  {computeIsBeta(activeEndpoint) && (
                    <span className="py-0.25 ml-1 rounded bg-blue-200 px-1 py-1 text-[10px] text-[#4559A4]">
                      Beta
                    </span>
                  )}
                  {computeIsNew(activeEndpoint) && (
                    <span className="py-0.25 ml-1 rounded bg-green-500 px-1 py-1 text-[10px] text-white">
                      New
                    </span>
                  )}
                  {isOpen ? (
                    <MdOutlineArrowDropDown className='arrowsm inline text-blue-500 text-3xl pb-1'/>
                  ) : (
                    <MdOutlineArrowRight className='arrowsm inline text-blue-500 text-3xl pb-1'/>
                  )}

                </div>
              </div>
            </div>
            {isOpen && (
              <div className="absolute w-[98%] bg-blue-50 dark:bg-gray-900 shadow-md mt-1 rounded-b-lg z-10 max-w-320:top-[50px] top-[50px] px-4 py-2 mx-auto">
                {uniqueEndpoints.map((endpoint, index) => {
                  const endpointModel = appModel.find(model => model.endpoint === endpoint);
                  const isDisabled = computeIsDisabled(endpoint);
                  const isBeta = computeIsBeta(endpoint);
                  const isNew = computeIsNew(endpoint);
                  return (
                    <ChatEndpoints
                      key={index}
                      endpoint={endpoint}
                      title={endpointModel?.title || 'Unknown Endpoint'}
                      EactiveEndpoint={activeEndpoint}
                      handleEndpointClick={handleDropdownClick}
                      isDisabled={isDisabled}
                      model={endpointModel.model}
                      isNew={isNew}
                      isBeta={isBeta}
                      showPricing={showPricing}
                      desciption={endpointModel?.desciption || {}}
                    />
                  );
                })}
              </div>
            )}

            {/* Mobile Models */}
            <div className="block md:grid md:grid-cols-10 gap-4 text-sm rounded-br-lg rounded-bl-lg rounded-tr-lg bg-white dark:bg-gray-900 dark:text-white/90 relative px-4 py-6 border-2 border-gray-100 dark:border-[#212429]">
              <div className="md:col-span-5 lg:col-span-3">
                { showModel ? (
                <ChatModels
                  models={filteredModels}
                  activeModel={activeModel}
                  handleOptionClick={handleModelClick}
                  showPricing={showPricing}
                  isBeta={false}
                  isNew={false}
                /> 
                ) : ( 
                  <></> 
                )}

                { activeEndpoint === 'openAI' ? (
                  <div className="hidden md:block relative lg:absolute md:bottom-0 mt-12 lg:mt-0 pb-6 md:pl-0 xl:pl-6">
                    <div
                    onMouseEnter={() => setIsHovered(true)} 
                    onMouseLeave={() => setIsHovered(false)}
                    className="relative inline-block"
                    >
                    <img 
                      src={isDarkMode ? "/assets/openai1a.svg" : "/assets/openai2a.svg"} 
                      alt="Powered by OpenAI" 
                      width="166" 
                      height="32" 
                      className="block mx-auto"
                    />
                    {isHovered && (
                      <a 
                      href="https://chatbotpro.org/disclaimer/" 
                      target="_blank" 
                      rel="noopener noreferrer"
                      className="absolute top-[10px] mt-2 w-[300px] text-center px-2 py-1 text-[12px] text-gray-600 bg-white border rounded-lg z-10 dark:bg-[#1a1d22] dark:text-gray-300 border-gray-500"
                    >
                      Chatbotpro is powered by OpenAI. We are neither affiliated nor related to OpenAI.
                    </a>
                    )}
                    </div>
                  </div>
                ) : ( 
                  <></> 
                )}
              </div>
              <div className="md:col-span-5 lg:col-span-7 md:min-h-[300px] pt-2">
                {appModel
                  .filter(model => model.model === activeModel)
                  .map((model) => (
                    <ChatModelDescription
                      key={animationKey}
                      title={model.model}
                      token={model.desciption.token}
                      disabled={model.desciption.disabled}
                    >
                      {model.desciption.content}
                    </ChatModelDescription>
                ))}

                <div className="grid grid-cols-1 mt-8">
                  <span className='py-2 text-[12px] dark:text-white/80 text-black/80 text-wrap'>You can start a conversation here or try the following examples:</span>
                  {activeEndpoint !== 'dallE' ? (
                    <>
                      <ChatExample
                        clickHandler={clickHandler}
                        text={localize('com_ui_example_quantum_computing')}
                      />
                      <ChatExample
                        clickHandler={clickHandler}
                        text={localize('com_ui_example_10_year_old_b_day')}
                      />
                      <ChatExample
                        clickHandler={clickHandler}
                        text={localize('com_ui_example_http_in_js')}
                      />
                    </>
                  ) : (
                    <>
                      <ChatExample
                        clickHandler={clickHandler}
                        text="A cozy treehouse café nestled in a lush, bioluminescent forest at twilight, with floating lanterns and a spiraling staircase"
                      />
                      <ChatExample
                        clickHandler={clickHandler}
                        text="A futuristic cityscape where nature and technology blend seamlessly, featuring hanging gardens, flying cars, and iridescent glass skyscrapers"
                      />
                      <ChatExample
                        clickHandler={clickHandler}
                        text="An otherworldly beach scene with crystal clear waters, rainbow-colored sand, and floating islands topped with exotic vegetation in a pastel sky"
                      />
                    </>
                  )}
                </div>
                { (activeEndpoint === 'openAI' || activeEndpoint === 'dallE') ? (
                    <div className="block md:hidden py-4 text-left">
                      <div
                    onMouseEnter={() => setIsHovered(true)} 
                    onMouseLeave={() => setIsHovered(false)}
                    className="relative inline-block"
                        >
                      <img 
                        src={isDarkMode ? "/assets/openai1a.svg" : "/assets/openai2a.svg"} 
                        alt="Powered by OpenAI" 
                        width="166" 
                        height="32" 
                        className="block text-left"
                      />
                      {isHovered && (
                        <a 
                        href="https://chatbotpro.org/disclaimer/" 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="absolute top-[10px] mt-2 w-[300px] text-center px-2 py-1 text-[12px] text-gray-600 bg-white border rounded-lg z-10 dark:bg-[#1a1d22] dark:text-gray-300 border-gray-500"
                      >
                        Chatbotpro is powered by OpenAI. We are neither affiliated nor related to OpenAI.
                      </a>
                      )}
                      </div>
                    </div>
                  ) : ( 
                    <></> 
                  )}
              </div>
            </div>
          </>
        ) : ( 
          <>
            {/* Desktop Endpoints */}
            <div
              className={cn('desktop w-full flex-col border-l-2 border-b-0 border-gray-100 dark:border-[#212429] gap-1 overflow-hidden rounded-tl-lg self-center text-sm font-medium md:flex md:flex-row sm:overflow-x-auto mb-[-3px] z-10',
                !isOpen ? 'hidden' : 'sm:flex top-20 z-10'
              )}
              ref={chatModelsRef}
            >
              {uniqueEndpoints.map((endpoint, index) => {
                const endpointModel = appModel.find(model => model.endpoint === endpoint);
                const isDisabled = computeIsDisabled(endpoint);
                const isBeta = computeIsBeta(endpoint);
                const isNew = computeIsNew(endpoint);

                return (
                  <ChatEndpoints
                    key={index}
                    endpoint={endpoint}
                    model={endpointModel.model}
                    showPricing={showPricing}
                    title={endpointModel?.title || 'Unknown Model'}
                    EactiveEndpoint={activeEndpoint}
                    handleEndpointClick={handleEndpointClick}
                    isDisabled={isDisabled}
                    isNew={isNew}
                    isBeta={isBeta}
                    desciption={endpointModel?.desciption || {}}
                  />
                );
              })}
            </div>
            {/* Desktop Models */}
            <div className="block md:grid md:grid-cols-12 gap-4 text-sm rounded-br-lg rounded-bl-lg rounded-tr-lg bg-white border-2 border-gray-100 dark:border-[#212429] dark:bg-[#1a1d22] dark:text-white/90 relative px-4 py-6">
              <div className="md:col-span-6 lg:col-span-4 overflow-y-auto max-h-[350px]">
                { showModel ? (
                <ChatModels
                  models={filteredModels}
                  activeModel={activeModel}
                  handleOptionClick={handleModelClick}
                  showPricing={showPricing}
                  isBeta={false}
                  isNew={false}
                /> 
                ) : ( 
                  <></> 
                )}

                { (activeEndpoint === 'openAI' || activeEndpoint === 'dallE') ? (
                  <div className="hidden md:block absolute md:bottom-0 mb-4 mt-12 lg:mt-0 pb-6 pl-7 max-w-800:pl-[3.5vw] max-w-768-850:pl-[2.5vw] max-w-851-991:pl-[5vw] max-w-991-1024:pl-[7vw] min-w-1280:pl-[45px] lg:pl-4 xl:pl-9">
                    <div
                    onMouseEnter={() => setIsHovered(true)} 
                    onMouseLeave={() => setIsHovered(false)}
                    className="relative inline-block">
                      <img 
                        src={isDarkMode ? "/assets/openai1a.svg" : "/assets/openai2a.svg"} 
                        alt="Powered by OpenAI" 
                        width="166" 
                        height="32" 
                        className="block mx-auto"
                      />
                      {isHovered && (
                        <a 
                        href="https://chatbotpro.org/disclaimer/" 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="absolute top-[10px] mt-2 w-[300px] text-center px-2 py-1 text-[12px] text-gray-600 bg-white border rounded-lg z-10 dark:bg-[#1a1d22] dark:text-gray-300 border-gray-500"
                      >
                        Chatbotpro is powered by OpenAI. We are neither affiliated nor related to OpenAI.
                      </a>
                      )}
                    </div>
                  </div>
                ) : ( 
                  <></> 
                )}
                </div>

                <div className="md:col-span-6 border-l border-gray-100/50 dark:border-[#212429] pl-4 lg:col-span-8 md:min-h-[350px]">
                {appModel
                  .filter(model => model.model === activeModel)
                  .map((model, index) => (
                    <ChatModelDescription
                    key={animationKey}
                      title={model.title}
                      token={model.desciption.token}
                      disabled={model.desciption.disabled}
                    >
                      {model.desciption.content}
                    </ChatModelDescription>
                ))}

              <motion.div className="grid grid-cols-1 mt-8 truncate overflow-hidden">
                <span className='py-2 text-[12px] dark:text-white/80 text-black/80 text-wrap'>You can start a conversation here or try the following examples:</span>
                {activeEndpoint !== 'dallE' ? (
                  <>
                    <ChatExample
                      clickHandler={clickHandler}
                      text={localize('com_ui_example_quantum_computing')}
                    />
                    <ChatExample
                      clickHandler={clickHandler}
                      text={localize('com_ui_example_10_year_old_b_day')}
                    />
                    <ChatExample
                      clickHandler={clickHandler}
                      text={localize('com_ui_example_http_in_js')}
                    />
                  </>
                ) : (
                  <>
                    <motion.div className='overflow-hidden inline-block pr-[1px] mr-[4px]'>
                      <ChatExample
                        activeEndpoint={activeEndpoint}
                        className="inline-block  max-w-[140px] max-w-1280:max-w-[90px] min-w-1281:max-w-[140px]"
                        clickHandler={clickHandler}
                        text="A cozy treehouse café nestled in a lush, bioluminescent forest at twilight, with floating lanterns and a spiraling staircase"
                      />
                      <ChatExample
                        activeEndpoint={activeEndpoint}
                        className="inline-block  max-w-[140px] max-w-1280:max-w-[90px] min-w-1281:max-w-[140px]"
                        clickHandler={clickHandler}
                        text="A futuristic cityscape where nature and technology blend seamlessly, featuring hanging gardens, flying cars, and iridescent glass skyscrapers"
                      />
                      <ChatExample
                        activeEndpoint={activeEndpoint}
                        className="inline-block  max-w-[140px] max-w-1280:max-w-[90px] min-w-1281:max-w-[140px]"
                        clickHandler={clickHandler}
                        text="An otherworldly beach scene with crystal clear waters, rainbow-colored sand, and floating islands topped with exotic vegetation in a pastel sky"
                      />
                    </motion.div>
                  </>
                )}
              </motion.div>
              { (activeEndpoint === 'openAI' || activeEndpoint === 'dallE') ? (
                  <div className="block md:hidden py-4 text-left">
                    <div 
                    onMouseEnter={() => setIsHovered(true)} 
                    onMouseLeave={() => setIsHovered(false)}
                    className="relative inline-block">
                      <img 
                        src={isDarkMode ? "/assets/openai1a.svg" : "/assets/openai2a.svg"} 
                        alt="Powered by OpenAI" 
                        width="166" 
                        height="32" 
                        className="block text-left"
                      />
                      {isHovered && (
                        <a 
                        href="https://chatbotpro.org/disclaimer/" 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="absolute top-[10px] mt-2 w-[300px] text-center px-2 py-1 text-[12px] text-gray-600 bg-white border rounded-lg z-10 dark:bg-[#1a1d22] dark:text-gray-300 border-gray-500"
                      >
                        Chatbotpro is powered by OpenAI. We are neither affiliated nor related to OpenAI.
                      </a>
                      )}
                    </div>
                  </div>
                ) : ( 
                  <></> 
                )}
              </div>
            </div>
          </>
        )}

      </motion.div>
    </div>
  );
}