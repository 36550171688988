import { useState, useRef } from 'react';
import { FaAngleRight } from 'react-icons/fa6';
import { RiTranslate2 } from 'react-icons/ri';
import languages from '~/components/helper/language';
import OnMouseLeave from '~/hooks/OnMouseLeave';
import OnMouseEnter from '~/hooks/OnMouseEnter';

export default function LanguageOption({
  submitLanguage,
}: {
  submitLanguage: (language: string) => void;
}) {
  const [isLanguageOptionOpen, setIsLanguageOptionOpen] = useState(false);
  const onChangeLanguage = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, language = '') => {
    e.preventDefault();
    setIsLanguageOptionOpen(false);
    submitLanguage(language);
  };

  const translateButton = useRef<HTMLDivElement>(null);
  OnMouseEnter(translateButton, () => {
    setIsLanguageOptionOpen(true);
  });
  OnMouseLeave(translateButton, () => {
    setIsLanguageOptionOpen(false);
  });

  return (
    <div ref={translateButton} className="relative sm:flex bg-[#F6F6F6] dark:bg-[#22252A] p-[4px] rounded-md">
      <div
        className="translate-option relative flex cursor-pointer items-center justify-center rounded-md border-none text-[#7B7B7B] dark:text-gray-400"
        onClick={() => {
          if (!isLanguageOptionOpen) {
            setIsLanguageOptionOpen(true);
          }
        }}
      >
        <RiTranslate2 className="absolute left-0 ml-1" />
        <div className="appearance-none bg-transparent px-6 outline-none">Translate</div>
        <FaAngleRight className="absolute right-0" />
      </div>
      {isLanguageOptionOpen && (
        <div className="relative">
          <div className="translate-options absolute bottom-6  left-0 z-[62] h-40 overflow-auto rounded-md border bg-white dark:bg-[#1a1d22] sm:top-0">
            {languages.map((language: any, index: number) => {
              if (language.value === 'Recommend' || language.value === 'All') {
                return (
                  <div className="cursor-default p-1 font-bold" key={index}>
                    {language.value}
                  </div>
                );
              }
              return (
                <div
                  className="px-4 text-gray-700  dark:text-gray-200 cursor-pointer"
                  key={index}
                  onClick={(e) => onChangeLanguage(e, language.value)}
                >
                  {language.value}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}
