import { usePopup, usePricingPopup, subscriptionType } from '../helper/store';
import { useRef, useEffect } from 'react';
import { showPricing } from '../helper/global';
const upgradeUrl = import.meta.env.VITE_UPGRADE_URL || 'https://chatbotpro.org/upgrade';
export default function Popup() {
  const pState = usePopup((state) => state);
  const { setType, setPaymentInterval } = subscriptionType((state) => state);
  const { setShowPricingPopup } = usePricingPopup((state) => state);
  const popupRef = useRef(null);
  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      pState.setShowPopup(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const getCookieValue = (key: string) => {
    const keyValue = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)');
    return keyValue ? decodeURIComponent(keyValue[2]) : null;
  };

  const clickContinue = () => {
    pState.setShowPopup(false);
    if (typeof AIPRO_USER !== 'undefined' && AIPRO_USER && AIPRO_USER.subscription_type) {
      const subscriptionType = AIPRO_USER.subscription_type;

      let mixPanelTrack = getCookieValue('mixPanelTrack');
      let mixPanelClickLocation = getCookieValue('mixPanelClickLocation');
      if (typeof pState.mixPanelTrack !== 'undefined' && pState.mixPanelTrack != '') {
        mixPanelTrack = pState.mixPanelTrack;
      }
      if (
        typeof pState.mixPanelClickLocation !== 'undefined' &&
        pState.mixPanelClickLocation != ''
      ) {
        mixPanelClickLocation = pState.mixPanelClickLocation;
      }
      console.log('chatpro-upgrade-modal popup:', mixPanelTrack, mixPanelClickLocation);
      if (typeof mixpanel != 'undefined') {
        mixpanel.track('chatpro-upgrade-modal', {
          upgrade: mixPanelTrack,
          'click-location': mixPanelClickLocation,
        });
      }

      const payment_interval = AIPRO_USER.payment_interval;
      setType(subscriptionType);
      setPaymentInterval(payment_interval);

      if (subscriptionType) {
        const is_pro_max = pState.displayText.toLowerCase().includes('pro max');
        if (is_pro_max) {
          if (upgradeUrl.includes('?')) {
            window.location.href = upgradeUrl + '&vjicon=1';
          } else {
            window.location.href = upgradeUrl + '?vjicon=1';
          }

          return;
        } else {
          window.location.href = upgradeUrl;
          return;
        }
      }
    } else {
      let mixPanelTrack = getCookieValue('mixPanelTrack');
      let mixPanelClickLocation = getCookieValue('mixPanelClickLocation');
      if (pState.mixPanelTrack != '') {
        mixPanelTrack = pState.mixPanelTrack;
      }
      if (pState.mixPanelClickLocation != '') {
        mixPanelClickLocation = pState.mixPanelClickLocation;
      }
      console.log('chatpro-upgrade-modal popup:', mixPanelTrack, mixPanelClickLocation);
      if (typeof mixpanel != 'undefined') {
        mixpanel.track('chatpro-upgrade-modal', {
          upgrade: mixPanelTrack,
          'click-location': mixPanelClickLocation,
        });
      }

      showPricing(() => {
        setShowPricingPopup(true);
      });
      return;
    }
    showPricing(() => {
      setShowPricingPopup(true);
    });
  };
  return (
    <>
      {pState.showPopup && (
        <>
          <div className="popup-overlay test3" />
          <div className="popup container mx-auto flex flex-col justify-between" ref={popupRef}>
            <h1
              className="popup-content mb-4 text-3xl font-bold"
              style={{ fontSize: pState.fSize }}
            >
              Get Full Access to {pState.displayText}
            </h1>
            <p className="text-md popup_desc">{pState.modalContent}</p>
            <button
              type="button"
              className="btn   bold mx-auto mt-6 w-1/3 bg-blue-600 px-12 py-4 text-center text-2xl text-white"
              onClick={() => {
                clickContinue();
              }}
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  clickContinue();
                }
              }}
            >
              Continue
            </button>
          </div>
        </>
      )}
    </>
  );
}
