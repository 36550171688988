import { forwardRef, ReactNode } from 'react';
import { cn } from '~/utils/';
import { useLocalize } from '~/hooks';

interface HeaderLinkProps {
  svg: () => ReactNode;
  text: string;
  pro?: boolean;
  isPro?: boolean; 
  isProMax?: boolean;
  clickHandler?: () => void;
  className?: string;
  [key: string]: any;
}

const HeaderLink = forwardRef<HTMLAnchorElement, HeaderLinkProps>((props, ref) => {
  const { svg, text, pro, isPro, isProMax, clickHandler, className = '', ...rest } = props;
  const localize = useLocalize();

  const combinedClassName = cn(
    'flex hover:cursor-pointer items-center gap-3 rounded-md py-3 px-3 text-normal text-black dark:text-white transition-colors duration-200 hover:bg-gray-500/10',
    className,
  );

  return (
    <a
      ref={ref}
      className={combinedClassName}
      onClick={clickHandler}
      {...rest}
    >
      {svg()}
      {text}
      {pro && !(isPro || isProMax) && text === localize('com_nav_themes') && (
        <span className="text-white ml-2 bg-[#177755] rounded-[3px] text-xs px-3 py-0.5">
          PRO
        </span>
      )}
    </a>
  );
});

export default HeaderLink;
