import React from 'react';
import CheckMark from '../svg/CheckMark';
import { GoPencil } from 'react-icons/go';

export default function RenameButton({ renaming, renameHandler, onRename }) {
  const handler = renaming ? onRename : renameHandler;
  return (
    <button className='rename-btn p-1' onClick={handler}>
      {renaming ? <CheckMark /> : <GoPencil />}
    </button>
  );
}
