import { forwardRef } from 'react';
import { cn } from '~/utils/';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';

const NavDropdown = forwardRef((props, ref) => {
  const { dropdownOpen, setDropdownOpen, svg, text, className = '', children, ...rest } = props;

  return (
    <div
      className={cn('nav-dropdown-btn-bg rounded-t-lg', {
        closed: !dropdownOpen,
      })}
    >
      <button
        aria-expanded={dropdownOpen}
        data-state={dropdownOpen ? 'open' : 'closed'}
        className={cn(
          'nav-menu-dropdown flex w-full cursor-pointer items-center gap-2 rounded-lg px-4 py-3 text-left text-sm text-white transition-colors duration-200 hover:bg-[#2685bd]',
          className,
        )}
        onClick={() => setDropdownOpen(!dropdownOpen)}
        ref={ref}
        type="button"
        {...rest}
      >
        {svg}
        <span className='font-semibold'>{text}</span>
        {dropdownOpen ? (
          <FaCaretDown size={20} role="img" aria-label={text} className="ml-auto" />
        ) : (
          <FaCaretUp size={20} role="img" aria-label={text} className="ml-auto" />
        )}
      </button>
      <div
        className={cn(
          'nav-dropdown-items-bg hidden w-full flex-col justify-center overflow-hidden px-2 py-3',
          {
            flex: dropdownOpen,
          },
        )}
      >
        {children}
      </div>
    </div>
  );
});

export default NavDropdown;
